import React, {useState,useEffect} from 'react'

function App() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetch("https://trypxa.in/flask-server")
      .then(res => {
        if (res.ok) {
          return res.text();
        } else {
          throw new Error("Flask server is not running.");
        }
      })
      .then(data => {
        setMessage(data);
        console.log(data);
      })
      .catch(error => {
        setMessage("Your app is running on ReactJS.");
        console.error(error);
      });
  }, []);

  return (
    <div>
      <h1>{message}</h1>
      <h2>Your app is running on ReactJS.</h2>
    </div>
  );
}


export default App
